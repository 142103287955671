import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderContact from '../components/HeaderContact'

class Contact extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Contact us" />
        <HeaderContact />
        <div id="main">
          <section id="content" className="main" style={{display: 'flex', justifyContent: 'center'}}>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSevPCZaSGvb64iuWeMKVi5cixqeZCjGhdnQTLwDNdPAWQMgsA/viewform?embedded=true"
              width="100%"
              height="1046"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Contact form"
            >
              Loading ...
            </iframe>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Contact;
