import React from 'react';
import {Link} from 'gatsby';
import logo from "../assets/images/beleserv.png";

const HeaderContact = (props) => (
    <header id="header" className="alt">
      <Link to="/"><span className="logo"><img src={logo} alt="" /></span></Link>
        <h1>Contact us</h1>
        <p></p>
    </header>
);

export default HeaderContact;
